import React from "react";
import { graphql } from "gatsby";
import initial from "lodash/initial";
import trim from "lodash/trim";
import last from "lodash/last";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import getVideoId from "get-video-id";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import PropTypes from "prop-types";
import {
  contentfulRichTextWithReferencesPropType,
  gatsbyImageDataPropType,
} from "~/propTypes";
import AnimatedBlock from "~/components/AnimatedBlock";
import BlogPostsSection from "~/components/blog/BlogPostsSection";
import YoutubeEmbed from "~/components/YoutubeEmbed";
import Layout from "~/components/layout/Layout";
import PageSectionList from "~/components/PageSectionList";
import ContentfulRichText, {
  defaultRenderHyperlink,
} from "~/components/ContentfulRichText";
import Seo from "~/components/Seo";
import "./BlogPost.scss";

function BlogPost({
  data: {
    recentPosts: { nodes: recentPosts },
    post,
    template,
  },
}) {
  const imageData = post.image
    ? getImage(post.image.gatsbyImageData)
    : undefined;
  return (
    <Layout>
      <Seo data={post.metadata} />
      <section>
        <div className="container">
          <div className="row">
            <div className="blog-post-head">
              <AnimatedBlock order={1}>
                <span className="category">{post.category}</span>
                <h1>{post.heading}</h1>
              </AnimatedBlock>
              <AnimatedBlock order={3}>
                <div className="line-yellow small-line-margin" />
              </AnimatedBlock>
              <AnimatedBlock order={5}>
                <p>{post.excerpt}</p>
              </AnimatedBlock>
              <div className="blog-meta-info">
                <span>{post.authorName}</span>
                <span className="dot-divider" />
                <span>{post.publishDate}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue-light section-pad-sm no-pad-top">
        <div className="container">
          <div className="row">
            <div className="post-body">
              {imageData ? (
                <div className="blog-post-img">
                  <GatsbyImage image={imageData} alt={post.image.title} />
                </div>
              ) : null}
              {post.videoUrl ? (
                <YoutubeEmbed sourceUrl={post.videoUrl} title={post.heading} />
              ) : null}
              <div className="blog-post-content">
                <ContentfulRichText
                  transformDoc={(doc) => {
                    // Use the side by side images rendering
                    return {
                      ...doc,
                      content: doc.content.reduce((accu, next) => {
                        if (
                          next.nodeType === BLOCKS.EMBEDDED_ASSET &&
                          accu.length > 0 &&
                          accu[accu.length - 1].nodeType ===
                            BLOCKS.EMBEDDED_ASSET
                        ) {
                          // Side by side image
                          return [
                            ...initial(accu),
                            {
                              nodeType: BLOCKS.PARAGRAPH,
                              data: {
                                sideBySideImages: true,
                              },
                              content: [last(accu), next],
                            },
                          ];
                        }
                        return [...accu, next];
                      }, []),
                    };
                  }}
                  content={post.content}
                  options={{
                    renderNode: {
                      [INLINES.HYPERLINK]: (node, children) => {
                        const href = node.data.uri;
                        const parsedVideo = getVideoId(href);
                        if (parsedVideo.service !== "youtube") {
                          return defaultRenderHyperlink(node, children);
                        }
                        const title =
                          Array.isArray(children) &&
                          typeof children[0] === "string"
                            ? children[0]
                            : "";
                        // Possible for some empty pcontent links to be maintained in the rich content
                        if (!title || !trim(title)) {
                          return null;
                        }
                        return <YoutubeEmbed sourceUrl={href} title={title} />;
                      },
                      [BLOCKS.EMBEDDED_ASSET]: (node) => {
                        const embeddedId = node?.data?.target?.sys?.id;
                        if (!embeddedId) {
                          return null;
                        }
                        const reference = post.content.references.find(
                          (r) => r.contentful_id === embeddedId
                        );
                        if (!reference) {
                          return null;
                        }
                        const embeddedImageData = getImage(
                          reference.gatsbyImageData
                        );
                        if (!embeddedImageData) {
                          return null;
                        }
                        const image = (
                          <GatsbyImage image={embeddedImageData} alt="" />
                        );
                        if (!reference.description) {
                          return image;
                        }
                        return (
                          <div>
                            {image}
                            <div className="blog-img-caption">
                              {reference.description}
                            </div>
                          </div>
                        );
                      },
                      [BLOCKS.PARAGRAPH]: (node, children) => {
                        if (node.data.sideBySideImages) {
                          return (
                            <div className="grid-post-2-col-img">
                              {children}
                            </div>
                          );
                        }

                        const childrenWithContent = children.filter((c) => !!c);
                        if (
                          childrenWithContent.length !== 1 ||
                          childrenWithContent[0].type !== YoutubeEmbed
                        ) {
                          return <p>{children}</p>;
                        }
                        return <div>{children}</div>;
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogPostsSection
        heading="Other recent posts"
        buttonText="View all posts"
        // TODO: Should we get this from the blog post template
        buttonLink="/blog"
        posts={recentPosts}
      />
      {template.sections ? <PageSectionList data={template.sections} /> : null}
    </Layout>
  );
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      metadata: Seo.propTypes.data,
      heading: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      publishDate: PropTypes.string.isRequired,
      image: PropTypes.shape({
        title: PropTypes.string.isRequired,
        gatsbyImageData: gatsbyImageDataPropType.isRequired,
      }),
      videoUrl: PropTypes.string,
      content: contentfulRichTextWithReferencesPropType.isRequired,
      category: PropTypes.string.isRequired,
      authorName: PropTypes.string.isRequired,
    }).isRequired,
    recentPosts: PropTypes.shape({
      nodes: BlogPostsSection.propTypes.posts,
    }).isRequired,
    template: PropTypes.shape({
      sections: PageSectionList.propTypes.data,
    }),
  }).isRequired,
};

const query = graphql`
  query BlogPostQuery($id: String!) {
    recentPosts: allContentfulBlogPost(
      limit: 3
      filter: { id: { ne: $id }, showOnSite: { eq: true } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        ...BlogPostsSection_posts
      }
    }
    post: contentfulBlogPost(id: { eq: $id }) {
      metadata {
        ...Seo_data
      }
      heading
      publishDate(formatString: "D MMMM YYYY")
      excerpt
      videoUrl
      image {
        title
        gatsbyImageData(
          placeholder: NONE
          backgroundColor: "white"
          layout: FULL_WIDTH
          aspectRatio: 2
          quality: 100
        )
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            description
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              backgroundColor: "white"
              quality: 90
            )
          }
        }
      }
      category
      authorName
    }
    template: contentfulPageTemplate(internalId: { eq: "Blog post" }) {
      sections {
        ...PageSectionList_data
      }
    }
  }
`;

export { query };
export default BlogPost;
